<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:52
-->
<template>
	<div class="baseVerify">
		<div class="baseVerify-content">
			<div class="activityVerify-table">
				<el-table :data="tableData" border>
					<el-table-column width="200px" prop="id" label="基地ID">
					</el-table-column>
					<el-table-column prop="name" label="共建基地">
					</el-table-column>
					<el-table-column prop="organization" label="所属组织">
					</el-table-column>
					<el-table-column width="120px" prop="createName" label="提交人">
					</el-table-column>
					<el-table-column width="160px" prop="createTime" label="提交时间">
					</el-table-column>
					<el-table-column width="200px" prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('verify', scope)">审核</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getBaseVerifyList
	} from "@/api/base"
	import storage from 'store'
	export default {
		data() {
			return {
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
			};
		},
		mounted() {
			this.baseVerifyList()
		},
		methods: {
			actionFn(type, scope) {
				switch (type) {
					case "verify":
						storage.set('verifyBase', scope.row)
						this.$router.push({
							path: `/baseVerify/verify?verifyId=${scope.row.id}`
						})
						break;
				}
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
			},
			// 共建基地审核列表
			baseVerifyList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				getBaseVerifyList(params).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	.baseVerify {
		.baseVerify-content {
			padding: 20px 20px;
		}
	}
</style>
